import binoculars from "../../assests/binoculars.png";
import info from "../../assests/info.png";
import userView from "../../assests/GAME - ICON - Queue no bubble ICON.png";
import lastWin from "../../assests/Last Win Button.png";
import cameraChanger from "../../assests/Switch Camera Button.png";
import Guide from "../../assests/How to Play Button.png";
import reportImage from "../../assests/Report Issue Icon.png";
import PlayImage from "../../assests/Play Button.png";
import ticketIcon from "../../assests/ticketNew_Hor.png";
import infoIcon from "../../assests/info.png";
import pointsBg from "../../assests/Price of Game BG.png";
import greenPrizeMove from '../../assests/Assistance Button.png'
import GrayPrizeMove from '../../assests/Assistance Pressed Button.png'
import winchaPopup from '../../assests/Wincha Pop-Up Icon.png'
import GamePricePng from '../../assests/Price of Game BG.png'
import timeOutImage from '../../assests/TIME OUT Button with TImer-01.png'
import LeftArrow from '../../assests/Left Arrow.png'
import RightArrow from '../../assests/Right Arrow.png'
import UpArrow from '../../assests/Up Arrow.png'
import LeftArrowPressed from '../../assests/Left Pressed Arrow.png'
import RightArrowPressed from '../../assests/Right Pressed Arrow.png'
import UpArrowPressed from '../../assests/Up Pressed Arrow.png'
// import timeOutImage from '../../assests/TIME OUT Button with TImer-01.png'
export const assets = {UpArrowPressed,LeftArrowPressed,RightArrowPressed,UpArrow,LeftArrow,RightArrow,timeOutImage,GamePricePng,winchaPopup,binoculars,info,userView,lastWin,cameraChanger,Guide,reportImage,PlayImage,infoIcon,pointsBg,greenPrizeMove,GrayPrizeMove,ticketIcon}