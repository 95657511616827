import React, { useEffect, useRef, useState } from "react";
import style from "./Cart.module.css";
import bg from "../../assests/Shipping Page BG.png";
import replay from "../../assests/Last Win Icon.png";
import share from "../../assests/Share Icon.png";
import ticket from "../../assests/Gold Ticket Standard Shipping.png";
import primeIcon from "../../assests/Wincha Clubhouse Option.png";
import ReactPlayer from "react-player";
import playBtn from "../../assests/PlayButton.png";
import { assets } from "../Description/assests";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import { AllAnimation } from "../../Animation/allAnimation";
import { MdClose, MdEmail, MdFacebook } from "react-icons/md";
import playVideo from "../../assests/PlayButton.png";
import { AiFillYoutube, AiOutlineInstagram } from "react-icons/ai";
import { FaTiktok } from "react-icons/fa";
import { TfiTwitter } from "react-icons/tfi";
import BundleSection from "../../assests/Prize Bundling_300dpi.png";
import FreeplaySection from "../../assests/Artboard 48 - Unlimited Freeplays and Icon.png";
import NotificationSection from "../../assests/Artboard 48 - Exclusive Prizes and Icon.png";
import ShippingSection from "../../assests/Artboard 48 - Free next day delivery and Icon.png";
import CloseImage from "../../assests/Artboard 48 X.png";
import Lower from "../../assests/Artboard 48 - Lower Image Split.png";
import Upper from "../../assests/club_house_top.png";
import XIcon from "../../assests/X Icon.png";
import { updateProfile } from "../../actions/user";
import { FaChevronDown } from "react-icons/fa";
import { music } from "../../assests/Musics/allMusic";
import { baseUrl } from "../url";
import PlayAudio from "../Audio/PlayAudio";
import { encrypt } from "../EncryptionUtils";
import bundleClose from "../../assests/Close Button.png";
import bundleImage from "../../assests/Pop Up Full Asset.png";
import winchaToken from "../../assests/Wincha Clubhouse Asset.png";
import tokenButton from "../../assests/Got It Full Button.png";
import ExclusiveImage from "../../assests/Artboard 48 - Unlock exclusive access.png"
import CancelText from "../../assests/Artboard 48 - Cancel any time TEXT.png"

const isSafari = () => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf("safari") > -1 && ua.indexOf("chrome") < 0;
};

const Cart = ({ gameMusic, setGameMusic, gameSound, setGameSound }) => {
  const token = JSON.parse(localStorage.getItem("token"));

  const newBaseKey = localStorage.getItem("baseKey");
  const newBaseIv = localStorage.getItem("baseIv");

  const dispatch = useDispatch();
  const [cartData, setCartData] = useState([]);
  const [premiumPopup, setPremiumPopup] = useState(false);
  const userId =
    localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"));
  const deviceId = JSON.parse(localStorage.getItem("deviceId"));

  const { configuration } = useSelector((state) => state.configuration);
  console.log(configuration);

  const { user } = useSelector((state) => state.profile);
  console.log(user);
  let bundleCount = localStorage.getItem("SaveShipping");
  const vidRef = useRef(null);
  const [shareId, setShareId] = useState("");
  const [shareIcons, setShareIcons] = useState(false);
  const [onPlay, setOnPlay] = useState(false);
  const [eGifting, setEGifting] = useState(true);
  const navigate = useNavigate();
  const [vipData, setVipData] = useState({});
  const [line1, setLine1] = useState(user ? user.addressline1 : "");
  const [line2, setLine2] = useState(user ? user.addressline2 : "");
  const [city, setCity] = useState(user ? user.city : "");
  const [state, setState] = useState(user ? user.state : "");
  const [url, setUrl] = useState("");
  const [zipcode, setZipCode] = useState(user ? user.zipcode : "");
  const [number, setNumber] = useState(user ? user.phone : "");
  const [firstName, setFirstName] = useState(user ? user.first_name : "");
  const [lastName, setLastName] = useState(user ? user.last_name : "");
  const [postcodetrue, setPostcodeTrue] = useState(false);
  const [phonenumber, setPhonenumber] = useState(false);
  const [allState, setAllState] = useState([]);
  const [selectState, setSelectState] = useState(false);
  const [userCountry, setUserCountry] = useState("");
  const [showVideo, setShowVideo] = useState(false);
  const [emptyCart, setEmptyCart] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isVip, setIsVip] = useState(false);
  const [isVipShown, setIsVipShown] = useState(false);
  const [isAddress, setIsAddress] = useState(false);
  const [isAddressShown, setIsAddressShown] = useState(false);
  const [isBundleReminder, setIsBundleReminder] = useState(false);
  const [isTopup, setIsTopup] = useState(false);
  const [isTopupShown, setIsTopupShown] = useState(false);
  const [isAddressField, setisAddressField] = useState(false);
  const [isAddressFieldShown, setIsAddressFieldShown] = useState(false);
  const [vipMessage, setVipMessage] = useState([]);
  const [products, setProducts] = useState([]);
  const [shareUrl, setShareUrl] = useState("");
  const [vipLimit, setVipLimit] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [addressObj, setAddressObj] = useState({
    line1: "",
    line2: "",
    city: "",
    state: "",
    zipcode: "",
  });
  const setPlayBack = () => {
    vidRef.current.playbackRate = 1.5;
  };
  console.log(userId);
  console.log(user && user.username !== "");
  const videoRef = useRef(null);
  const [errors, setError] = useState("");
  const [checkError, setCheckError] = useState(false);
  const [count, setCount] = useState(1);
  const [checkOutValue, setCheckOutValue] = useState(1);
  const [checkOutStatus, setCheckOutStatus] = useState(false);
  const [accountSuspend, setAccountSuspend] = useState(false);
  const [userExist, setUserExist] = useState(false);
  const [checkOutActive, setCheckOutActive] = useState(false);
  const [bundleStatus, setBundleStatus] = useState(true);

  useEffect(() => {
    console.log(checkOutValue);
  }, [checkOutValue]);

  const checkStateExits = (state, e) => {
    e.preventDefault();
    if (state.status === false) {
      setCheckError(true);
      setState("");
      setSelectState(false);
    } else {
      setState(state);
      setSelectState(false);
    }
  };
  const handlePlayVideo = () => {
    vidRef.current.play();
  };
  const handlePauseVideo = () => {
    vidRef.current.pause();
  };

  const addressCheck = () => {
    if (user && user?.vip) {
      if (user && user.first_name === "") {
        setIsAddress(true);
        setCheckOutStatus(true);
      } else {
        if (vipData.status === true && vipData.data[0].vip_token) {
          setIsVip(true);
        } else {
          stripeAPI();
        }
      }
    } else {
      if (user && user.first_name === "") {
        setIsAddress(true);
        setCheckOutStatus(true);
      } else {
        stripeAPI();
      }
    }
  };

  const handleShare = async (link) => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: "Wincha_Online Game",
          url: link,
        });
        console.log("Successfully shared");
      } catch (error) {
        console.error("Error sharing:", error);
        // Fallback to copy-to-clipboard if sharing fails
        copyToClipboard(link);
      }
    } else {
      // Fallback to copy-to-clipboard if navigator.share is not supported
      copyToClipboard(link);
    }
  };

  const copyToClipboard = (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();

    try {
      document.execCommand("copy");
      setIsCopied(true);
      console.log("Copied to clipboard");
    } catch (error) {
      console.error("Error copying to clipboard:", error);
    }

    document.body.removeChild(textArea);
  };

  async function checkFreePlay() {
    const userBody = {
      // user: user&&user.username===""?"":userId,
      user: userId === "" ? "" : userId,
      device_id: deviceId,
      source: "web",
      type: "web",
    };
    await fetch(`${baseUrl}/game/webapp/freeplay/limit`, {
      method: "POST",
      body: JSON.stringify(userBody),
      headers: {
        "Content-Type": "application/json",
        "access-token": `${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.data[0].account_suspension === true) {
          setAccountSuspend(true);
        }
        if (data.data[0].account_exists === false) {
          setUserExist(true);
        }

        console.log(data.description);
      });
  }

  async function fetchCart() {
    await fetch(`${baseUrl}/cart/webapp/collection`, {
      method: "POST",
      body: JSON.stringify({
        user_id: userId,
      }),
      headers: {
        "Content-Type": "application/json",
        "access-token": `${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setCartData(data.data);

        setLoading(false);
        console.log(data);
        [...data.data].forEach((cart) => {
          setProducts((products) => [
            ...products,
            {
              id: `${cart.id}`,
              is_Egifting: cart.is_Egifting,
              quantity: "1",
              cart_id: cart.cart_id,
            },
          ]);
          if (cart.is_Egifting === false) {
            setEGifting(false);
            console.log(cart);
          }
        });
      });
  }
  const stateFetch = () => {
    fetch(`${baseUrl}/configurations/state/collections`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "access-token": `${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setAllState(data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    stateFetch();
  }, []);
  async function addAddress() {
    setCheckOutActive(true);
    if (configuration.COUNTRY_CODE === "44") {
      setUserCountry("county");
    } else {
      setUserCountry("state");
    }
    const body = {
      user_id: userId,
      username: user.username,
      first_name: firstName,
      last_name: lastName,
      phone: number,
      addressline1: line1,
      addressline2: line2,
      city: city,
      state: state,
      zipcode: zipcode,
      coutrycode: configuration.COUNTRY_CODE,
      coutryname: configuration.COUNTRY_NAME,
    };
    console.log(body);

    if (configuration.COUNTRY_CODE === "1") {
      const body = {
        user_id: userId,
        username: user.username,
        first_name: firstName,
        last_name: lastName,
        phone: `${number}`,
        addressline1: line1,
        addressline2: line2,
        city: city,
        state: state,
        zipcode: zipcode,
        coutrycode: configuration.COUNTRY_CODE,
        coutryname: configuration.COUNTRY_NAME,
      };
    }
    const newData = JSON.stringify(body);
    const plaintext = newData;
    // const key = "ocpwincha4tR5d0P";
    // const iv = "ocpwincha7h3XrYb";
    const key = newBaseKey;
    const iv = newBaseIv;
    console.log(newBaseKey);
    console.log(newBaseIv);

    const encryptedText = encrypt(plaintext, key, iv);
    console.log(encryptedText, "encrypted_text");
    const newBody = {
      title: encryptedText,
      source: "web",
      type: "web",
    };
    await fetch(`${baseUrl}/user/webapp/shipping/details/update`, {
      method: "PUT",
      body: JSON.stringify(newBody),
      headers: {
        "Content-Type": "application/json",
        "access-token": `${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(body);
        console.log(data);
        if (data.status === "True") {
          dispatch(updateProfile());
          setisAddressField(false);
          setCheckOutActive(false);
          if (checkOutStatus && user && user?.vip) {
            setCheckOutValue(3);
          } else if (checkOutStatus && user && user?.vip !== true) {
            setCheckOutValue(4);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  async function vipPayment() {
    localStorage.setItem("navLink", "cart");
    const requestData = {
      mode: "subscription",
      subscriptionPriceId: configuration.STRIPE_SUBSRIPTION_ID_ONE_MONTH,
      amount: parseFloat(configuration.VIP_SUBSCRIPTION_AMOUNT).toFixed(2) * 100,
      quantity: 1,
      success_url: `${window.location.origin}/payment/success/?session_id={CHECKOUT_SESSION_ID}`,
      cancel_url: `${window.location.origin}/cart/?session_id={CHECKOUT_SESSION_ID}`,
      // "currency":"inr",
      currency: configuration.CURRENCY_CODE,
      product: "vip",
      payment_mode: "vip",
      user_id: userId,
      credict_point: `${configuration.VIP_BONUS_POINT}`,
      sub_version:2
    };
    await fetch(`${baseUrl}/points/webapp/create-checkout-session`, {
      method: "POST",
      body: JSON.stringify(requestData),
      headers: {
        "Content-Type": "application/json",
        "access-token": `${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        window.location.assign(`${data.data[0].url}`);
      });
  }

  async function stripeAPI() {
    setCheckOutValue(1);
    const requestData2 = {
      mode: "payment",
      amount:
        parseFloat(configuration?.standard_shipping_amount).toFixed(2) * 100,
      quantity: 1,
      success_url: `${window.location.origin}/order-confirmed/?session_id={CHECKOUT_SESSION_ID}`,
      cancel_url: `${window.location.origin}/cart/?session_id={CHECKOUT_SESSION_ID}`,
      currency: configuration.CURRENCY_CODE,
      product: configuration?.standard_shipping_amount,
      payment_mode: "checkout",
      user_id: userId,
      credict_point: "0",
    };
    await fetch(`${baseUrl}/points/webapp/create-checkout-session `, {
      method: "POST",
      body: JSON.stringify(requestData2),
      headers: {
        "Content-Type": "application/json",
        "access-token": `${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        console.log(requestData2);
        window.location.assign(`${data.data[0].url}`);
      });
  }

  function getVipDetails() {
    fetch(`${baseUrl}/user/v3/webapp/vip/shipping/status `, {
      method: "POST",
      body: JSON.stringify({
        user_id: userId,
        source: "web",
      }),
      headers: {
        "Content-Type": "application/json",
        "access-token": `${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setVipData(data);
        // const message = data.data[0].vip_discription.split("\n");
        setVipMessage(data.data[0]);
        console.log(data);
      });
  }
  async function numberValidation() {
    await fetch(`${baseUrl}/user/webapp/phonecode/check`, {
      method: "POST",
      body: JSON.stringify({
        user: userId,
        number: `${configuration.COUNTRY_CODE}${number}`,
      }),
      headers: {
        "Content-Type": "application/json",
        "access-token": `${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.data[0].valid === true) {
          postCodeCheck();
        } else {
          setPhonenumber(true);
        }
      });
  }
  async function postCodeCheck() {
    await fetch(`${baseUrl}/configurations/webapp/code/check    `, {
      method: "POST",
      body: JSON.stringify({
        country: "UK",
        code: zipcode,
      }),
      headers: {
        "Content-Type": "application/json",
        "access-token": `${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "True") {
          addAddress();
        } else {
          setPostcodeTrue(true);
        }
        console.log(data);
      });
  }
  async function checkoutAPi() {
    const sendData = {
      address_1: user.addressline1,
      address_2: user.addressline2,
      city: user.city,
      company: "",
      country: configuration.COUNTRY_NAME,
      email: user.email,
      first_name: user.username,
      phone: user.phone,
      postcode: user.zipcode,
      products: products,
      state: user.state,
      user_id: userId,
    };
    const newData = JSON.stringify(sendData);
    const plaintext = newData;
    // const key = "ocpwincha4tR5d0P";
    // const iv = "ocpwincha7h3XrYb";
    const key = newBaseKey;
    const iv = newBaseIv;
    console.log(newBaseKey);
    console.log(newBaseIv);

    const encryptedText = encrypt(plaintext, key, iv);
    console.log(encryptedText, "encrypted_text");
    const newBody = {
      title: encryptedText,
      source: "web",
      type: "web",
    };
    await fetch(`${baseUrl}/cart/checkout`, {
      method: "POST",
      body: JSON.stringify(newBody),
      headers: {
        "Content-Type": "application/json",
        "access-token": `${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(sendData);
        setProducts([]);
        console.log(data.data[0]);
        if (data.status === "True") {
          navigate("/order-confirmed");
          dispatch(updateProfile());
        }
        console.log(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  useEffect(() => {
    fetchCart();
    getVipDetails();
  }, []);

  useEffect(() => {
    if (userId && deviceId) {
      checkFreePlay();
    }
  }, [userId, deviceId]);

  function checkCount() {
    console.log("Checking count", count);

    if (vipData.status === false) {
      setCount(2);
    }
  }
  function Popup(url) {
    console.log(url);
    return (
      <div
        className={showVideo ? style.LastWinPopup : style.hideVideopopup}
        onClick={() => {}}
      >
        <div
          className={style.VideoOverlay}
          onClick={() => {
            setShowVideo(false);
            setOnPlay(false);
          }}
        ></div>
        <div className={style.PlayIcon}>
          {onPlay === true ? (
            <button
              onClick={() => {
                setOnPlay(false);
                handlePauseVideo();
              }}
            >
              <img src={assets.PlayImage} alt="" />
            </button>
          ) : (
            <button
              onClick={() => {
                setOnPlay(true);
                handlePlayVideo();
              }}
            >
              <img src={playVideo} alt="" />
            </button>
          )}
        </div>
        <div className={style.VideoSection}>
          {/* <MdClose
            onClick={() => {
              setShowVideo(false);
              setOnPlay(false);
            }}
          /> */}
          {url === "" ? (
            <div className={style.VideoEmpty}>
              <p>Whoops! Video unavailable Please try again later.</p>
            </div>
          ) : (
            <video
              autoPlay
              muted={true}
              ref={videoRef}
              onCanPlay={() => setPlayBack()}
            >
              <source src={url} type="video/mp4" />
            </video>
          )}
          {}
          {}
        </div>
      </div>
    );
  }
  function lowPoint() {
    const pointInt = parseInt(user.point);
    const priceInt = parseInt(configuration.STANDARD_SHIPPING_PRICE);
    if (pointInt < priceInt) {
      setIsTopup(true);
    } else {
      setCount(3);
    }
  }
  const videoParentRef = useRef();
  const [shouldUseImage, setShouldUseImage] = useState(false);
  useEffect(() => {
    // check if user agent is safari and we have the ref to the container <div />
    if (isSafari() && videoParentRef.current) {
      // obtain reference to the video element
      const player = videoParentRef.current.children[0];

      // if the reference to video player has been obtained
      if (player) {
        // set the video attributes using javascript as per the
        // webkit Policy
        player.controls = false;
        player.playsinline = true;
        player.muted = true;
        player.setAttribute("muted", ""); // leave no stones unturned :)
        player.autoplay = true;

        // Let's wait for an event loop tick and be async.
        setTimeout(() => {
          // player.play() might return a promise but it's not guaranteed crossbrowser.
          const promise = player.play();
          // let's play safe to ensure that if we do have a promise
          if (promise.then) {
            promise
              .then(() => {})
              .catch(() => {
                // if promise fails, hide the video and fallback to <img> tag
                videoParentRef.current.style.display = "none";
                setShouldUseImage(true);
              });
          }
        }, 0);
      }
    }
  }, []);
  return (
    <div className={style.Container}>
      {/* <audio ref={audioRefHome} onEnded={audioEnded} loop></audio> */}
      <PlayAudio
        gameMusic={gameMusic}
        setGameMusic={setGameMusic}
        gameSound={gameSound}
        setGameSound={setGameSound}
      />
      {premiumPopup ? (
        <div className={style.clubHousePopup}>
          <div
            className={style.clubHouseOverlay}
            onClick={() => {
              setPremiumPopup(false);
            }}
          ></div>
          <div className={style.ClubHouse}>
            <div className={style.TopImage}>
              <div
                className={style.clubHouseClose}
                onClick={() => {
                  setPremiumPopup(false);
                }}
              >
                {}
                <img src={CloseImage} alt="" />
              </div>
              <div className={style.topImageContainer}><img src={Upper} alt="" /></div>
              <div className={style.exclusiveImage}>
                <img src={ExclusiveImage} alt="exclusiveImage"/>
              </div>
            </div>
            <div className={style.BottomContents}>
              {/* <div className={style.BonusPoints}>
                <div className={style.Bonus}>
                  <p>{configuration.VIP_BONUS_POINT}W</p>
                </div>
                <div className={style.BonusText}>
                  <p>Sign Up Bonus!</p>
                </div>
              </div> */}
              <div className={style.benefits}>
              <div className={style.benefit}>
                  <div className={style.benefitImage}>
                    <img src={FreeplaySection} alt="" />
                  </div>
                </div>
                {/* <div className={style.benefit}>
                  <div className={style.benefitImage}>
                    <img src={BundleSection} alt="" />
                  </div>
                </div> */}
                <div className={style.benefit}>
                  <div className={style.benefitImage}>
                    <img src={NotificationSection} alt="" />
                  </div>
                </div>
                <div className={style.benefit}>
                  <div className={style.benefitImage}>
                    <img src={ShippingSection} alt="" />
                  </div>
                </div>
              </div>
              <div className={style.SubscribeButton}>
                <button
                  onClick={() => {
                    if (userId === null) {
                      return navigate("/login");
                    }
                    vipPayment();
                  }}
                >{`${configuration.CURRENCY_SYMBOL}${configuration.VIP_SUBSCRIPTION_AMOUNT} Per Month`}</button>
              </div>
              <div className={style.CancelSubscription}>
              <img src={CancelText} alt=""/>
              </div>
            </div>
            <div className={style.TermsAndPolicy}>
              <div
                className={style.Terms}
                onClick={() => {
                  window.open(`${configuration.terms}`, "_blank");
                }}
              >
                <p>Subscription Terms</p>
              </div>
              <div
                className={style.Policy}
                onClick={() => {
                  window.open(`${configuration.privacy}`, "_blank");
                }}
              >
                <p>Privacy Policy</p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {showVideo ? (
        <div
          className={showVideo ? style.LastWinPopup : style.hideVideopopup}
          onClick={() => {}}
        >
          <div
            className={style.VideoOverlay}
            onClick={() => {
              setShowVideo(false);
              setOnPlay(false);
            }}
          ></div>
          <div className={style.VideoSection}>
            {/* <MdClose
              onClick={() => {
                setShowVideo(false);
                setOnPlay(false);
              }}
            /> */}
            {url === "" ? (
              <div className={style.VideoEmpty}>
                <p>Whoops! Video unavailable Please try again later.</p>
              </div>
            ) : (
              <div
                ref={videoParentRef}
                dangerouslySetInnerHTML={{
                  __html: `
        <video
          loop
          muted
          autoplay
          playsinline
          preload="metadata"
        >
        <source src="${url}" type="video/mp4" />
        <source src="${url}" type="video/MPEG-4" />
        <source src="${url}" type="video/avc" />
        </video>`,
                }}
              ></div>
            )}
            {}
            {}
          </div>
        </div>
      ) : (
        ""
      )}

      {isVip && (
        <div className={style.tokenOverlay}>
          <div className={style.tokenPopup}>
            <div className={style.tokenCloseContainer}>
              <div
                className={style.tokenClose}
                onClick={() => {
                  setIsVip(false);
                  navigate("/order-confirmed");
                }}
              >
                <img src={CloseImage} alt="" />
              </div>
            </div>

            <div className={style.tokenSection}>
              <div className={style.tokenWincha}>
                <img src={winchaToken} alt="" />
              </div>
              <div className={style.tokenHeader}>
                <div className={style.tokenValue}>
                  <div className={style.tokenTxt1}>
                    <p>{vipMessage.vip_description_1}</p>
                    <p>{vipMessage.vip_description_1}</p>
                  </div>
                  <div className={style.tokenTxt2}>
                    <p>OF</p>
                    <p>OF</p>
                  </div>
                  <div className={style.tokenTxt3}>
                    <p>{vipMessage.vip_description_2}</p>
                    <p>{vipMessage.vip_description_2}</p>
                  </div>
                </div>
                <div className={style.tokenText}>
                  <p>{vipMessage.vip_description_3}</p>
                  <p>{vipMessage.vip_description_3}</p>
                </div>
              </div>
              <div className={style.tokenDescription}>
                <p>{vipMessage.vip_description_4}</p>
                <p>{vipMessage.vip_description_4}</p>
              </div>
              <div className={style.tokenImageContainer}>
                <div
                  className={style.tokenButton}
                  onClick={() => {
                    setIsVip(false);
                    navigate("/order-confirmed");
                  }}
                >
                  <img src={tokenButton} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* {isVip === true ? (
        <div className={style.popup}>
          <div className={style.popupImage}>
            <img src={assets.winchaPopup} alt="" />
          </div>
          <div className={style.popupText}>
            {vipMessage.map((text) => {
              console.log(text);
              return (
                <p className={text ? style.PopupTextContent : style.Blank}>{`${
                  text ? text : text === "" ? "Blank" : ""
                }`}</p>
              );
            })}
            <p></p>
            {}
          </div>
          <div className={style.ReportPopupButton}>
            <button
              onClick={() => {
                const parsedPoint = user && parseInt(user.point);
                const parsedPrice =
                  configuration &&
                  parseInt(configuration.STANDARD_SHIPPING_PRICE);
                setIsVip(false);
                setCount(2);
                if (parsedPoint > parsedPrice) {
                  setIsAddress(true);
                  setCount(4);
                  console.log("count jumbed 2");
                }
                if (user && user.addressline1 === "") {
                  setIsAddress(true);
                  setCount(3);
                  console.log("count jumbed 2");
                } else {
                  setCount(4);
                }
              }}
            >
              OK
            </button>
          </div>
        </div>
      ) : (
        ""
      )} */}

      {vipLimit && (
        <div className={style.tokenOverlay}>
          <div className={style.tokenPopup}>
            <div className={style.tokenCloseContainer}>
              <div
                className={style.tokenClose}
                onClick={() => {
                  setVipLimit(false);
                }}
              >
                <img src={CloseImage} alt="" />
              </div>
            </div>

            <div className={style.tokenSection}>
              <div className={style.tokenWincha}>
                <img src={winchaToken} alt="" />
              </div>
              <div className={style.tokenHeader}>
                <div className={style.tokenNoValue}>
                  <p>{vipMessage.vip_discription1}</p>
                  <p>{vipMessage.vip_discription1}</p>
                </div>
                <div className={style.tokenNoText}>
                  <p>{vipMessage.vip_discription2}</p>
                  <p>{vipMessage.vip_discription2}</p>
                </div>
              </div>
              <div className={style.tokenNoDescription}>
                <p>{vipMessage.vip_discription3}</p>
                <p>{vipMessage.vip_discription3}</p>
              </div>
              <div className={style.tokenImageContainer}>
                <div
                  className={style.tokenButton}
                  onClick={() => {
                    setVipLimit(false);
                  }}
                >
                  <img src={tokenButton} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* {vipLimit === true ? (
        <div className={style.popup}>
          <div className={style.popupImage}>
            <img src={assets.winchaPopup} alt="" />
          </div>
          <div className={style.popupText}>
            {vipMessage.map((text) => {
              console.log(text);
              return (
                <p className={text ? style.PopupTextContent : style.Blank}>{`${
                  text ? text : text === "" ? "Blank" : ""
                }`}</p>
              );
            })}
            <p></p>
            {}
          </div>
          <div className={style.ReportPopupButton}>
            <button
              onClick={() => {
                setVipLimit(false);
              }}
            >
              OK
            </button>
          </div>
        </div>
      ) : (
        ""
      )} */}

      {isAddress && (
        <div>
          <div
            className={style.AddressTitleOverlay}
            onClick={() => {
              setIsAddress(false);
            }}
          ></div>
          <div className={style.popup}>
            <div className={style.popupImage}>
              <img src={assets.winchaPopup} alt="" />
            </div>
            <div className={style.DetailspopupText}>
              {}
              <p>Whoops! We need your shipping details</p>
              {}
            </div>
            <div className={style.ReportPopupButton}>
              <button
                onClick={() => {
                  setisAddressField(true);
                  setIsAddress(false);
                }}
              >
                ADD DETAILS
              </button>
            </div>
          </div>
        </div>
      )}

      {isBundleReminder && (
        <div className={style.bundleOverlay}>
          <div className={style.bundlePopup}>
            <div className={style.bundleCloseContainer}>
              <div className={style.bundleClose}>
                <img
                  src={bundleClose}
                  alt=""
                  onClick={() => {
                    setIsBundleReminder(false);
                    setCheckOutValue(2);
                    setBundleStatus(false);
                  }}
                />
              </div>
            </div>
            <div
              className={style.bundleSection}
              onClick={() => {
                setIsBundleReminder(false);
                setCheckOutValue(2);
                setBundleStatus(false);
              }}
            >
              <img src={bundleImage} alt="" />
            </div>
          </div>
        </div>
      )}

      {/* {isBundleReminder === true ? (
        <div className={style.popup}>
          <div className={style.popupImage}>
            <img src={assets.winchaPopup} alt="" />
          </div>
          <div className={style.popupText}>
            <p>
              Woah there! Remember to bundle your prizes to save on shipping!
            </p>
          </div>
          <div className={style.ReportPopupButton}>
            <button
              onClick={() => {
                if (vipData.status === true) {
                  setCount(1);
                } else {
                  setCount(2);
                }
                setIsBundleReminder(false);
                localStorage.setItem("SaveShipping", true);
              }}
            >
              OK
            </button>
          </div>
        </div>
      ) : (
        ""
      )} */}

      {isTopup && isTopupShown === false ? (
        <div className={style.popup}>
          <div className={style.popupImage}>
            <img src={assets.winchaPopup} alt="" />
          </div>
          <div className={style.popupText}>
            <p>Woah there! You haven't got enough tickets</p>
          </div>
          <div className={style.ReportPopupButton}>
            <button
              onClick={() => {
                navigate("/tickets");
                setCount(3);
                setIsTopup(false);
                localStorage.setItem("navLink", "cart");
              }}
            >
              TOP UP
            </button>
          </div>
        </div>
      ) : (
        ""
      )}

      {accountSuspend ? (
        <>
          <div className={style.Overlay}></div>
          <div className={style.popup}>
            <div className={style.popupImage}>
              <img src={assets.winchaPopup} alt="" />
            </div>
            <div className={style.popupText}>
              <p>Your account was suspended</p>
            </div>
            <div className={style.ReportPopupButton}>
              <button
                onClick={(e) => {
                  console.log("clicked");
                  localStorage.removeItem("user");
                  localStorage.removeItem("SaveShipping");
                  localStorage.removeItem("times");
                  localStorage.removeItem("premium");
                  localStorage.removeItem("lastNotificationId");
                  localStorage.removeItem("userType");
                  localStorage.removeItem("iosPopupShown");
                  navigate("/login");
                  window.location.reload();
                }}
              >
                OK
              </button>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      {userExist ? (
        <>
          <div className={style.Overlay}></div>
          <div className={style.popup}>
            <div className={style.popupImage}>
              <img src={assets.winchaPopup} alt="" />
            </div>
            <div className={style.popupText}>
              <p>User does not exist</p>
            </div>
            <div className={style.ReportPopupButton}>
              <button
                onClick={(e) => {
                  console.log("clicked");
                  localStorage.removeItem("user");
                  localStorage.removeItem("SaveShipping");
                  localStorage.removeItem("times");
                  localStorage.removeItem("premium");
                  localStorage.removeItem("lastNotificationId");
                  localStorage.removeItem("userType");
                  localStorage.removeItem("iosPopupShown");
                  navigate("/login");
                  window.location.reload();
                }}
              >
                OK
              </button>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      {isAddressField === true &&
      userId !== null &&
      user &&
      user.username !== "" ? (
        <div className={style.Address}>
          <div
            className={style.AddressTitleOverlay}
            onClick={() => {
              setSelectState(false);
            }}
          ></div>
          <form action="">
            <h1>Shipping Address</h1>
            <input
              type="text"
              name=""
              id=""
              value={firstName}
              placeholder="FIRST NAME"
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
            />
            <input
              type="text"
              name=""
              id=""
              value={lastName}
              placeholder="LAST NAME"
              onChange={(e) => {
                setLastName(e.target.value);
              }}
            />
            <input
              type="text"
              name=""
              id=""
              value={line1}
              placeholder="LINE 1"
              onChange={(e) => {
                setLine1(e.target.value);
              }}
            />
            <input
              type="text"
              name=""
              id=""
              value={line2}
              placeholder="LINE 2"
              onChange={(e) => {
                setLine2(e.target.value);
              }}
            />
            <input
              type="text"
              name=""
              id=""
              value={number}
              placeholder="PHONE NUMBER"
              onChange={(e) => {
                setNumber(e.target.value);
              }}
            />
            <input
              type="text"
              name=""
              id=""
              value={city}
              placeholder="CITY"
              onChange={(e) => {
                setCity(e.target.value);
              }}
            />
            {user.coutrycode === "1" ? (
              <div className={`${style.input} ${style.selectInput}`}>
                {state.state ? (
                  <input
                    type="text"
                    readOnly
                    value={state.state}
                    className={style.StateSelect}
                  />
                ) : (
                  <input
                    type="text"
                    readOnly
                    className={style.StateSelectCenter}
                    placeholder="SELECT STATE"
                  />
                )}
                {}
                <FaChevronDown
                  className={style.Downarrow}
                  onClick={() => {
                    selectState ? setSelectState(false) : setSelectState(true);
                  }}
                />
                {selectState ? (
                  <div className={selectState ? style.AllState : style.stateUp}>
                    {allState.map((stateItem) => {
                      return (
                        <input
                          type="text"
                          name="state"
                          id="state"
                          readOnly
                          value={stateItem.state}
                          onClick={(e) => {
                            checkStateExits(stateItem, e);
                          }}
                        />
                      );
                    })}
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              <input
                type="text"
                name=""
                id=""
                value={state}
                placeholder="COUNTY"
                onChange={(e) => {
                  setState(e.target.value);
                }}
              />
            )}
            {user.coutrycode === "44" ? (
              <input
                type="text"
                name=""
                id=""
                value={zipcode}
                placeholder="POSTCODE"
                onChange={(e) => {
                  setZipCode(e.target.value);
                }}
              />
            ) : (
              <input
                type="text"
                name=""
                id=""
                value={zipcode}
                placeholder="ZIP/POSTAL CODE"
                onChange={(e) => {
                  setZipCode(e.target.value);
                }}
              />
            )}
            <button
              type="submit"
              onClick={() => {
                setIsAddressFieldShown(true);
                setisAddressField(false);
                setAddressObj({
                  line1,
                  line2,
                  city,
                  state,
                  zipcode,
                });
                numberValidation();
                console.log(addressObj);
              }}
              disabled={
                line1 === "" ||
                line2 === "" ||
                city === "" ||
                state === "" ||
                zipcode === ""
              }
            >
              CONFIRM
            </button>
          </form>
        </div>
      ) : (
        ""
      )}
      {checkError ? (
        <div className={style.popup}>
          <div className={style.popupImage}>
            <img src={assets.winchaPopup} alt="" />
          </div>
          <div className={style.popupText}>
            <p>State is not available for shipping</p>
          </div>
          <div className={style.ReportPopupButton}>
            <button
              onClick={() => {
                setCheckError(false);
                setSelectState(true);
              }}
            >
              Ok
            </button>
          </div>
        </div>
      ) : (
        ""
      )}
      <div className={style.Cart}>
        <div className={style.Title}>
          <p>BASKET</p>
        </div>
        {}
        <div className={style.Carts}>
          {loading === false ? (
            userId !== null && user && user.username !== "" ? (
              cartData.length < 1 ? (
                <p className={style.CartEmptyText}>
                  {" "}
                  Your Basket is Currently Empty
                </p>
              ) : (
                ""
              )
            ) : (
              <p className={style.CartEmptyText}>
                {" "}
                Your Basket is Currently Empty
              </p>
            )
          ) : (
            ""
          )}
          {/* {cartData.length < 1 ? (
            <p className={style.CartEmptyText}>  Your Basket is Currently Empty” 
            
            </p>
          ) : (
            ""
          )} */}
          {loading ? (
            <div className={style.LoaderDiv}>
              <div className={style.LoaderAnime}>
                <Lottie animationData={AllAnimation.Loader} />
              </div>
            </div>
          ) : (
            cartData.map((cart, index) => {
              return (
                <div className={style.CartItem}>
                  <div className={style.Game}>
                    <div className={style.image}>
                      <img src={cart.featured_image.thumbnail} alt="" />
                    </div>
                    <div className={style.name}>
                      <p>{cart.title}</p>
                    </div>
                  </div>
                  <div className={style.Actions}>
                    <div className={style.replay}>
                      <img
                        src={replay}
                        alt=""
                        onClick={() => {
                          console.log(cart.game_share_url);
                          setShowVideo(true);
                          setUrl(cart.game_share_url);
                        }}
                      />
                    </div>
                    <div className={style.share}>
                      {shareIcons && shareId === cart.id * index ? (
                        <div
                          className={style.ShareOverlay}
                          onClick={() => {
                            setShareIcons(false);
                          }}
                        ></div>
                      ) : (
                        ""
                      )}
                      {shareIcons && shareId === cart.id * index ? (
                        <div className={style.ShareDiv}>
                          <div className={style.ShareIcon}>
                            <MdFacebook
                              onClick={() => {
                                window.open("https://www.facebook.com/");
                              }}
                            />
                          </div>
                          <div className={style.ShareIcon}>
                            <AiOutlineInstagram
                              onClick={() => {
                                window.open("https://www.instagram.com/");
                              }}
                            />
                          </div>
                          {/* <div className={style.ShareIcon}>
                            <TfiTwitter
                              onClick={() => {
                                window.open("https://twitter.com/home");
                              }}
                            />
                          </div> */}
                          <div className={style.ShareIcon}>
                            <img
                              src={XIcon}
                              alt=""
                              onClick={() => {
                                window.open("https://twitter.com/home");
                              }}
                            />
                          </div>
                          <div className={style.ShareIcon}>
                            <FaTiktok
                              onClick={() => {
                                window.open("https://www.tiktok.com/about/");
                              }}
                            />
                          </div>
                          <div className={style.ShareIcon}>
                            <AiFillYoutube
                              onClick={() => {
                                window.open("https://www.youtube.com/");
                              }}
                            />
                          </div>
                          <div className={style.ShareIcon}>
                            <MdEmail
                              onClick={() => {
                                window.open(
                                  `mailto:?body=${encodeURIComponent(shareUrl)}`
                                );
                              }}
                            />
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      <img
                        src={share}
                        alt=""
                        onClick={() => {
                          setShareUrl(cart.video_url);
                          console.log(cart.game_share_url);
                          // shareIcons
                          //   ? setShareIcons(false)
                          //   : setShareIcons(true);
                          // setShareId(cart.id * index);
                          handleShare(cart.video_url);
                        }}
                      />
                    </div>
                  </div>
                  {/* {isCopied && <p>Copied to clipboard!</p>} */}
                </div>
              );
            })
          )}
        </div>
        {(user &&
          user.username !== "" &&
          user &&
          user.username !== undefined) ||
        cartData.length !== 0 ? (
          <div className={style.BottomCart}>
            {eGifting ? (
              ""
            ) : (
              <div className={style.Shipping}>
                <div className={style.PrimeShipping}>
                  <div
                    className={style.shippingIcon}
                    onClick={() => {
                      if (
                        vipData.status === true &&
                        vipData.data[0].vip_token === false
                      ) {
                        setPremiumPopup(true);
                      }
                      if (user && user.vip === false) {
                        setPremiumPopup(true);
                      }
                    }}
                  >
                    <img src={primeIcon} alt="" />
                  </div>
                  <div className={style.selection}>
                    <div className={style.price}>
                      <p>FREE</p>
                    </div>
                    {vipData.status === true &&
                    vipData.data[0].vip_token === true ? (
                      <span
                        className={style.CircleActive}
                        onClick={() => {
                          if (user && user.vip === false) {
                            setPremiumPopup(true);
                          }
                        }}
                      ></span>
                    ) : (
                      <span
                        className={style.Circle}
                        onClick={() => {
                          if (userId === null) {
                            return navigate("/login");
                          }
                          if (user && user.vip === false) {
                            setPremiumPopup(true);
                          }
                          if (
                            vipData.status === true &&
                            vipData.data[0].vip_token === false
                          ) {
                            if (bundleStatus) {
                              setCheckOutValue(1);
                              setVipLimit(true);
                            } else {
                              setCheckOutValue(4);
                              setVipLimit(true);
                            }
                          }
                        }}
                      ></span>
                    )}
                  </div>
                </div>
                <div className={style.FreeShipping}>
                  <div className={style.shippingIcon}>
                    <p>Standard Shipping</p>
                    <p> {configuration?.standard_shipping_days} days</p>
                  </div>
                  <div className={style.selection}>
                    {/* <div className={style.ticket}>
                      <img src={ticket} alt="" />
                    </div> */}
                    <div className={style.price}>
                      {/* <div className={style.priceImage}>
                        <img src={euro} alt="" />
                      </div> */}
                      {/* <p>&#163;</p> */}
                      <p>{configuration?.CURRENCY_SYMBOL}</p>
                      <p>{configuration?.standard_shipping_amount}</p>
                    </div>
                    {vipData.status === false ? (
                      <span
                        className={style.CircleActive}
                        onClick={() => {}}
                      ></span>
                    ) : vipData.status === true &&
                      vipData.data[0].vip_token === false ? (
                      <span
                        className={style.CircleActive}
                        onClick={() => {}}
                      ></span>
                    ) : (
                      <span className={style.Circle} onClick={() => {}}></span>
                    )}
                  </div>
                </div>
              </div>
            )}
            {emptyCart ? (
              <div className={style.popup}>
                <div className={style.popupImage}>
                  <img src={assets.winchaPopup} alt="" />
                </div>
                <div className={style.popupText}>
                  <p>Your Basket is Currently Empty</p>
                </div>
                <div className={style.ReportPopupButton}>
                  <button
                    onClick={() => {
                      setEmptyCart(false);
                      setCount(4);
                    }}
                  >
                    OK
                  </button>
                </div>
              </div>
            ) : (
              ""
            )}

            <div className={style.DeliveryAddress}>
              <div className={style.AddressHeadSeciton}>
                <div className={style.AddressTitle}>
                  <p>Delivery Address</p>
                </div>
                <div className={style.AddressEditBtn}>
                  <button
                    onClick={() => {
                      setIsAddressFieldShown(false);
                      console.log("Edited");
                      setisAddressField(true);
                    }}
                  >
                    Edit
                  </button>
                </div>
              </div>
              <div className={style.AddressSection}>
                {/* <input
                type="text"
                placeholder="House"
                value={user ? user.username : "-"}
              />
                 */}
                <input
                  type="text"
                  placeholder="Line 1"
                  value={user ? user.addressline1 : "-"}
                />
                <input
                  type="text"
                  placeholder="Line 2"
                  value={user ? user.addressline2 : "-"}
                />
                <input
                  type="text"
                  placeholder="City"
                  value={user ? user.city : "-"}
                />
                <input
                  type="text"
                  placeholder="Country"
                  value={user ? user.state : "-"}
                />
                <input
                  type="text"
                  placeholder="Postcode"
                  value={user ? user.zipcode : "-"}
                />
              </div>
            </div>
            {phonenumber ? (
              <div className={style.postpopup}>
                <div className={style.popupImage}>
                  <img src={assets.winchaPopup} alt="" />
                </div>
                <div className={style.phnpopupText}>
                  <p>Invalid Phone Number </p>
                </div>
                <div className={style.popupbutton}>
                  <button
                    onClick={() => {
                      setPhonenumber(false);
                      setisAddressField(true);
                    }}
                  >
                    Ok
                  </button>
                </div>
              </div>
            ) : (
              ""
            )}
            {postcodetrue ? (
              <div className={style.postpopup}>
                <div className={style.popupImage}>
                  <img src={assets.winchaPopup} alt="" />
                </div>
                <div className={style.popupText}>
                  <p>Post code invalid</p>
                </div>
                <div className={style.popupbutton}>
                  <button
                    onClick={() => {
                      setPostcodeTrue(false);
                      setisAddressField(true);
                    }}
                  >
                    Ok
                  </button>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className={style.Checkout}>
              <button
                onClick={() => {
                  if (checkOutActive !== true) {
                    if (checkOutValue === 1 && bundleStatus) {
                      setIsBundleReminder(true);
                    } else if (checkOutValue === 2) {
                      addressCheck();
                    } else if (checkOutValue === 3) {
                      if (
                        vipData.status === true &&
                        vipData.data[0].vip_token
                      ) {
                        setIsVip(true);
                      }
                    } else if (checkOutValue === 4) {
                      stripeAPI();
                    }
                  }
                }}
                disabled={loading}
              >
                CHECKOUT
              </button>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
export default Cart;
